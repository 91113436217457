import React, { useEffect } from "react";
import "./scss/CapacityFundingPassportInput.scss";

/** ASSETS */
import { ReactComponent as Close } from "assets/svg/card-input-close-icon.svg";

interface IProps {
  label: string;
  inputName: string;
  value: string;
  CBHandleChange: (event: any) => void;
  error?: boolean | null;
}

const CapacityFundingPassportInput: React.FC<IProps> = ({
  label,
  inputName,
  value,
  CBHandleChange,
  error,
}) => {
	console.log("error", error);
  return (
    <div className="capacity-funding-passport-input">
      <div className="headers-label">
        <div className="label">
          <span>{label}</span>
        </div>
      </div>

      {error && (
        <div
          className={
            error
              ? "flex items-center content-center isError "
              : "flex items-center content-center value-passport "
          }
        >
          <input
            name={inputName}
            value={value}
            onChange={CBHandleChange}
            type="text"
            required
            // style={{width: (value.length + 1) * 13}}
          />
        </div>
      )}
      {!error && (
        <div className={"flex items-center content-center value-passport"}>
          <input
            name={inputName}
            value={value}
            onChange={CBHandleChange}
            type="text"
            required
            // style={{width: (value.length + 1) * 13}}
          />
        </div>
      )}
    </div>
  );
};

export default CapacityFundingPassportInput;
function setIsEmpty(arg0: boolean) {
  throw new Error("Function not implemented.");
}
