import React, { useState, useEffect } from "react";
import "../scss/OnboardingHelpModal.scss";

/** ASSETS */
import { ReactComponent as Info } from "assets/svg/info-picto.svg";

/** COMPONENTS */
import OnboardingThumb from "components/common/OnboardingThumb/OnboardingThumb.component";

/** CONSTANTS */
import { COnboardingHelpModalData } from "../constants/COnboardingHelpModalData.constant";

/** ENUMS */
import { EFirebaseEvents } from "enums/EFirebaseEvents.enum";

/** FIREBASE */
import analytics from "firebase-config";
import { logEvent } from "@firebase/analytics";

/** HOOKS */
import { useAppSelector } from "hooks";

/** INTERFACES */
import { IOnboardingHelpModalData } from "../interfaces/IOnboardingHelpModalData.interface";
import { IOnboardingHelpModalDataList } from "../interfaces/IOnboardingHelpModalDataList.interface";

/** LODASH */
import { get } from "lodash";

/** MATERIAL UI */
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

/** REACT ICONS */
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

interface IProps {
	open: boolean;
	step: number;
	CBHandleClose: () => void;
}

const Transition = React.forwardRef<unknown, TransitionProps>(
	function Transition(props: any, ref: any) {
		return <Slide direction="up" timeout={1000} ref={ref} {...props} />;
	}
);

const OnboardingHelpModal: React.FC<IProps> = ({
	open,
	step,
	CBHandleClose,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [infos, setInfos] = useState<IOnboardingHelpModalData | null>(null);

	useEffect(() => {
		const modalData: IOnboardingHelpModalData | undefined =
			COnboardingHelpModalData[step];

		if (modalData === undefined) {
			setIsOpen(false);
			CBHandleClose();
		} else {
			setInfos(modalData);
		}

		// eslint-disable-next-line
	}, [step]);

	useEffect(() => {
		if (open) {
			const modalEvents: Array<string> = [
				"ONBOARDING_HOW_INCOMES",
				"ONBOARDING_HOW_CREDITS",
				"ONBOARDING_HOW_CONTRIBUTION",
			];

			if (step <= modalEvents.length) {
				const event: EFirebaseEvents | undefined = get(
					EFirebaseEvents,
					modalEvents[step]
				);

				event !== undefined && logEvent(analytics, event);
			}
		}
		setIsOpen(open);
	}, [open, step]);

	const handleClose = (feedback?: string) => {
		const eventsSteps: Array<string> = [
			"ONBOARDING_HOW_INCOMES",
			"ONBOARDING_HOW_CREDITS",
			"ONBOARDING_HOW_CONTRIBUTION",
		];
		const event: string | undefined = get(EFirebaseEvents, eventsSteps[step]);

		if (feedback === "not-helpful" && event !== undefined) {
			logEvent(analytics, `${event}_NO`);
		}
		if (feedback === "helpful" && event !== undefined) {
			logEvent(analytics, `${event}_YES`);
		}

		setIsOpen(false);
		CBHandleClose();
	};

	const displayList = () => {
		return (
			<div className="list">
				{infos?.listTitle && (
					<span className="list-title">{infos.listTitle}</span>
				)}

				<ul>
					{infos?.list.map(
						(item: IOnboardingHelpModalDataList, index: number) => {
							return (
								<li key={index}>
									<span>{item.title} </span>
									<span>{item.content}</span>
								</li>
							);
						}
					)}
				</ul>
			</div>
		);
	};

	const displayQuestionAnswer = () => {
		return (
			<div className="column question-answer">
				<span>{infos?.question}</span>
				<span>{infos?.answer}</span>
			</div>
		);
	};

	const displayFeedback = () => {
		return (
			<div className="column feedback">
				<span>Cette information vous a-t-elle aidé ?</span>
				<div className="flex content-center">
					<OnboardingThumb
						icon={<FaThumbsDown />}
						color="red-thumb"
						CBHandleClick={() => {
							handleClose("not-helpful");
						}}
						style={{ background: "#FFE9DC" }}
					/>

					<OnboardingThumb
						icon={<FaThumbsUp />}
						color="green-thumb"
						CBHandleClick={() => {
							handleClose("helpful");
						}}
						style={{ background: "#D8FFDC" }}
					/>
				</div>
			</div>
		);
	};

	if (infos === null) {
		return <></>;
	}

	return (
		<Dialog
			className="onboarding-help-modal"
			open={isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => handleClose()}
		>
			<DialogContent>
				<button className="close" onClick={() => handleClose()}>
					<IoClose />
				</button>
				<div className="column content-center content">
					<div className="icon">
						<Info />
					</div>
					<h3>{infos.title}</h3>
					<span className="subtitle">{infos.subtitle}</span>

					{displayList()}

					{infos.question !== null && displayQuestionAnswer()}
					<div className="separator" />
					{displayFeedback()}
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default OnboardingHelpModal;
