import React, { useState } from "react";
import "./scss/CapacityDetailsModal.scss";

/** ASSETS */
import { ReactComponent as CreditIcon } from "assets/svg/credit-picto-white.svg";
import { ReactComponent as ChevronIcon } from "assets/svg/blue-chevron-left.svg";

/** COMPONENTS */
import DetailsTab from "components/common/DetailsTab/DetailsTab.component";
import DetailsItem from "components/common/DetailsItem/DetailsItem.component";
import DownloadAppModal from "components/common/DownloadAppModal/DownloadAppModal.component";

/** ENUMS */
import { EFirebaseEvents } from "enums/EFirebaseEvents.enum";

/** FIREBASE */
import { logEvent } from "firebase/analytics";
import analytics from "firebase-config";

/** MATERIAL UI */
import { Dialog, DialogContent } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

interface IProps {
	CBHandleClose: () => void;
	data: any;
}

const Transition = React.forwardRef<unknown, TransitionProps>(
	function Transition(props: any, ref: any) {
		const isDesktop: boolean = window.innerWidth > 769;

		return <Slide direction={isDesktop ? "left" : "up"} ref={ref} {...props} />;
	}
);

const CapacityDetailsModal: React.FC<IProps> = ({ CBHandleClose, data }) => {
	// const capacity: any = useAppSelector(selectStoreCapacity);
	const [displayDownloadModal, setDisplayDownloadModal] =
		useState<boolean>(false);
	const [tabValue, setTabValue] = useState<number>(0);

	console.log('CAPACITY', data);

	const handleClick = (name: string) => {
	  if (name === "Ma capacité") {
		logEvent(analytics, EFirebaseEvents.CAPACITY_DETAILS_RATE_PRESSED);
		setTabValue(0);
	  }
	  if (name === "Mon taux") {
		logEvent(analytics, EFirebaseEvents.CAPACITY_DETAILS_RATE_PRESSED);
		setTabValue(1);
	  }
	};

	return (
		<>
			<Dialog
				className="capacity-details-modal"
				open={true}
				TransitionComponent={Transition}
				keepMounted
				onClose={CBHandleClose}
			>
				<DialogContent>
					<div className="column modal-dialog-content">
						<button className="close" onClick={CBHandleClose}>
							<ChevronIcon />
						</button>

						<div className="header">
							<h2>Détails</h2>
						</div>
						<div className="container">
							<DetailsTab
								items={["Ma capacité", "Mon taux"]}
								selected={tabValue}
								CBHandleClick={handleClick}
							/>
							{tabValue === 0 && (
                			<div>
								<DetailsItem
									title="Ma capacité d'achat"
									content="Prix maximum du bien que vous pourriez acheter."
									value={Math.round(data.result)}
									suffix={" €"}
									noBackground={true}
								/>

								<DetailsItem
									icon={<CreditIcon />}
									title={"Emprunt"}
									content={
										"Il s'agit du montant que vous pourriez emprunter à la banque."
									}
									value={Math.round(data.borrowingCapitalWithInsurance || 0)}
									suffix={" €"}
								/>

								<DetailsItem
									icon={<CreditIcon />}
									title={"Apport"}
									content={
										"Il est recommandé d'apporter le montant des frais de votre projet."
									}
									value={Math.round(data.personalContribution)}
									suffix={" €"}
								/>

								<DetailsItem
									icon={<CreditIcon />}
									title={"Frais"}
									content={
										"Ces frais correspondent aux frais de garantie et aux frais de notaire estimés."
									}
									value={Math.round(data.newGlobalFees ?? 0)}
									suffix={" €"}
								/>
							</div>
              			)}
						{tabValue === 1 && (
							<div>
								<DetailsItem
								icon={<CreditIcon />}
								title={"Taux"}
								content={"Fridaa. analyse en temps réel le marché en fonction de vos revenus et de la durée d'emprunt."}
								// EDIT HERE
								value={data.rate ? Math.round(data.rate * 10000) / 100.0 : 0}
								suffix={" %"}
								/>
							</div>
						)}
						</div>
					</div>
				</DialogContent>
			</Dialog>

			{displayDownloadModal && (
				<DownloadAppModal
					CBHandleClose={() => setDisplayDownloadModal(false)}
				/>
			)}
		</>
	);
};

export default CapacityDetailsModal;
