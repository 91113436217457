import React from "react";
import "./scss/EstateTypeButton.scss";

interface IProps {
	title: string;
	icon: any;
	type: number;
	selectedType: number | null;
	CBHandleChange: (type: number) => void;
}

const EstateTypeButton: React.FC<IProps> = ({
	title,
	icon,
	type,
	selectedType,
	CBHandleChange
}) => {
	const handleClick = (): void => {
		
		CBHandleChange(type);
	}

	return (
		<button className="column estate-type-button" 
		style={{background: selectedType === type ? "var(--light-yellow)" : "transparent"}}
		onClick={handleClick}>
			<div className="icon">{icon}</div>
			<div className="title">
				<span>{title}</span>
			</div>
		</button>
	);
};

export default EstateTypeButton;
