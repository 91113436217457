import React, { useState, useEffect } from "react";
import "./scss/PropertyFundingTypeSelectorModal.scss";

/** ASSETS */
import { ReactComponent as ChevronDown } from "assets/svg/blue-chevron-left.svg";
import { ReactComponent as Check } from "assets/svg/white-check.svg";

/** COMPONENTS */
import EstateTypeButton from "../EstateTypeButton/EstateTypeButton.component";
import CapacityFundingTypeInputCard from "components/common/CapacityFundingTypeInputCard/CapacityFundingTypeInputCard.component";

/** CONSTANTS */
import { CAllPropertyTypes } from "constants/CAllPropertyTypes.constant";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** INTERFACES */
import { IPropertyTypeNameIcon } from "interfaces/IPropertyTypeNameIcon.interface";

/** MATERIAL UI */
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

/** STORE */
import { selectStoreCapacityEstateType } from "features/capacity/capacityFundingSlice";
import {
  selectStoreCapacityPrice,
  selectStoreCapacityWorksPrice,
  selectStoreCapacityLandPrice,
  selectStoreCapacityConstructPrice,
  updateFundingStoreCapacity,
} from "features/capacity/capacityFundingSlice";
import { numberWithoutSpaces } from "utils/numberWithoutSpaces";
import { numberWithSpaces } from "utils/numberWithSpaces";

interface IProps {
  open: boolean;
  CBHandleClose: () => void;
  CBHandleChange: (type: number | null) => void;
}

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props: any, ref: any) {
    const isDesktop: boolean = window.innerWidth > 769;

    return <Slide direction={isDesktop ? "left" : "up"} ref={ref} {...props} />;
  }
);

const PropertyTypeSelectorModal: React.FC<IProps> = ({
  open,
  CBHandleClose,
  CBHandleChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useAppDispatch();

  const selectedType: any = useAppSelector(selectStoreCapacityEstateType);
  const price: number | null = useAppSelector(selectStoreCapacityPrice);
  const worksPrice: number | null = useAppSelector(
    selectStoreCapacityWorksPrice
  );
  const landPrice: number | null = useAppSelector(selectStoreCapacityLandPrice);
  const constructPrice: number | null = useAppSelector(
    selectStoreCapacityConstructPrice
  );

  // console.log('ESTATE', selectedType, price, worksPrice, landPrice, constructPrice);

  const [tempSelectedType, setTempSelectedType] = useState<any>(useAppSelector(selectStoreCapacityEstateType));
  useEffect(() => setTempSelectedType(selectedType), [selectedType]);
  const [tempPrice, setTempPrice] = useState<number | null>(useAppSelector(selectStoreCapacityPrice));
  useEffect(() => setTempPrice(price), [price]);
  const [tempWorksPrice, setTempWorksPrice] = useState<number | null>(useAppSelector(selectStoreCapacityWorksPrice));
  useEffect(() => setTempWorksPrice(worksPrice), [worksPrice]);
  const [tempLandPrice, setTempLandPrice] = useState<number | null>(useAppSelector(selectStoreCapacityLandPrice));
  useEffect(() => setTempLandPrice(landPrice), [landPrice]);
  const [tempConstructPrice, setTempConstructPrice] = useState<number | null>(useAppSelector(selectStoreCapacityConstructPrice));
  useEffect(() => setTempConstructPrice(constructPrice), [constructPrice]);

  // console.log('TEMP ESTATE', tempSelectedType, selectedType, tempPrice, price, tempWorksPrice, tempLandPrice, tempConstructPrice);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = () => {
    setIsOpen(false);
    CBHandleClose();
  };
  const handleReset = () => {
    dispatch(updateFundingStoreCapacity({ price: 0 }));
    dispatch(updateFundingStoreCapacity({ workPrice: 0 }));
    dispatch(updateFundingStoreCapacity({ landPrice: 0 }));
    dispatch(updateFundingStoreCapacity({ constructPrice: 0 }));

  }
  const handleSubmit = (): void => {
    CBHandleChange(tempSelectedType);
    handleReset();
    if (tempSelectedType === 0 || tempSelectedType === 3) {
      console.log('selectedType === 0')
      dispatch(updateFundingStoreCapacity({ price: tempPrice }));
    }
    if (tempSelectedType === 1) {
      console.log('selectedType === 1')
      dispatch(updateFundingStoreCapacity({ price: tempPrice }));
      dispatch(updateFundingStoreCapacity({ worksPrice: tempWorksPrice }));
    }
    if (tempSelectedType === 2) {
      console.log('selectedType === 2')
      dispatch(updateFundingStoreCapacity({ landPrice: tempLandPrice }));
      dispatch(
        updateFundingStoreCapacity({ constructPrice: tempConstructPrice })
      );
    }
  };

  const handleChangeType = (newEstateType: number) => {
    setTempSelectedType(newEstateType);
  };

  const handleChangePrice = (newPrice: any) => {
    setTempPrice(numberWithoutSpaces(newPrice.target.value));
  };
  const handleChangeWorksPrice = (newWorksPrice: any) => {
    setTempWorksPrice(numberWithoutSpaces(newWorksPrice.target.value));
  };
  const handleChangeLandPrice = (newLandPrice: any) => {
    setTempLandPrice(numberWithoutSpaces(newLandPrice.target.value));
  };
  const handleChangeConstructPrice = (newConstructPrice: any) => {
    setTempConstructPrice(numberWithoutSpaces(newConstructPrice.target.value));
  };

  const displayHeader = () => {
    return (
      <div className="column dialog-header">
        <h2>Sélectionner un type de bien</h2>
      </div>
    );
  };

  return (
    <Dialog
      className="property-type-funding-selector-modal"
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogContent>
        <div>
          <button className="close" onClick={handleClose}>
            <ChevronDown />
          </button>
          {displayHeader()}

          <div className="flex space-around flex-wrap">
            {CAllPropertyTypes.map(
              (item: IPropertyTypeNameIcon, index: number) => {
                return (
                  <EstateTypeButton
                    title={item.title}
                    icon={item.icon}
                    type={item.type}
                    selectedType={tempSelectedType}
                    CBHandleChange={handleChangeType}
                    key={index}
                  />
                );
              }
            )}
          </div>
          {tempSelectedType === 0 && (
            <div className="column">
              <CapacityFundingTypeInputCard
                label={"Prix"}
                inputName="Price"
                units={["€"]}
                value={tempPrice === null ? "" : numberWithSpaces(tempPrice)}
                CBHandleChange={handleChangePrice}
              />
            </div>
          )}
          {tempSelectedType === 1 && (
            <div className="column">
              <CapacityFundingTypeInputCard
                label={"Prix"}
                units={["€"]}
                inputName="Price"
                value={tempPrice === null ? "" : numberWithSpaces(tempPrice)}
                CBHandleChange={handleChangePrice}
              />
              <CapacityFundingTypeInputCard
                units={["€"]}
                label={"Prix des travaux"}
                inputName="worksPrice"
                value={tempWorksPrice === null ? "" : numberWithSpaces(tempWorksPrice)}
                CBHandleChange={handleChangeWorksPrice}
              />
            </div>
          )}
          {tempSelectedType === 2 && (
            <div className="column">
              <CapacityFundingTypeInputCard
                label={"Prix du terrain"}
                units={["€"]}
                inputName="landPrice"
                value={tempLandPrice === null ? "" : numberWithSpaces(tempLandPrice)}
                CBHandleChange={handleChangeLandPrice}
              />
              <CapacityFundingTypeInputCard
                units={["€"]}
                label={"Prix de construction"}
                inputName="constructPrice"
                value={tempConstructPrice === null ? "" : numberWithSpaces(tempConstructPrice)}
                CBHandleChange={handleChangeConstructPrice}
              />
            </div>
          )}
          {tempSelectedType === 3 && (
            <div className="column">
              <CapacityFundingTypeInputCard
                units={["€"]}
                label={"Prix"}
                inputName="Price"
                value={tempPrice === null ? "" : numberWithSpaces(tempPrice)}
                CBHandleChange={handleChangePrice}
              />
            </div>
          )}
          <div className="submit">
            <button className="flex content-center" onClick={handleSubmit}>
              <span>Valider</span>
              <Check />
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PropertyTypeSelectorModal;
