import React, { useEffect, useState } from "react";
import "./scss/CapacityFunding.scss";

/** COMPONENTS */
import InputValueBlock from "components/common/InputValueBlock/InputValueBlock.component";
import LoanDurationSelector from "components/common/LoanDurationSelector/LoanDurationSelector.component";
import PropertyFundingTypeSelector from "components/common/PropertyFundingTypeSelector/PropertyFundingTypeSelector.component";
import ColoredResultCard from "components/ColoredFundingResultCard/ColoredFundingResultCard.component";
import DiscoverFundingPasseportButton from "components/common/DiscoverFundingPasseportButton/DiscoverFundingPasseportButton.component";
import CapacityDetailsModal from "components/CapacityFundingDetailsModal/CapacityFundingDetailsModal.component";
import PassportModal from "components/PassportModal/PassportModal.component";
import DownloadAppModal from "components/common/DownloadFundingAppModal/DownloadFundingAppModalPhoneEmail.component";

/** ENUMS */
import { EFirebaseEvents } from "enums/EFirebaseEvents.enum";

/** FIREBASE */
import { logEvent } from "firebase/analytics";
import analytics from "firebase-config";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** INTERFACES */
import { IBankRates } from "interfaces/IBankRates.interface";

/** LODASH */
import { get } from "lodash";

/** SERVICES */
import capacityService from "services/capacityFunding.service";
import bankRatesService from "services/bankRates.service";
import apiService from "services/api.service";

/** STORE */
import {
  selectStoreCapacity,
  updateFundingStoreCapacity,
} from "features/capacity/capacityFundingSlice";
import { roundFor } from "utils/roundFor.util";

const initialState: any = {
  monthlyIncomes: null,
  mainBorrowerIncomes: null,
  coBorrowerIncomes: null,
  monthlyCredits: null,
  personalContribution: null,
  // type: 0,
  constructPrice: null,
  landPrice: null,
  price: null,
  worksPrice: null,
  age: null,
  ageCoborrower: null,
  duration: 25,
  creditCost: null,
  monthlyPayment: null,
  contribution: null,
  result: null,
  newGlobalFees: null,
  borrowingCapitalWithInsurance: null,
  debtCapacity: null,
  estateType: 0,
};

const CapacityFundingScreen: React.FC = () => {
  const [isDekstop, setIsDesktop] = useState<boolean>(false);
  const [dataFetched, setDataFetched] = useState<any>(initialState);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayDownloadAppModal, setDisplayDownloadAppModal] =
    useState<boolean>(false);
  const [displayDetailsModal, setDisplayDetailsModal] =
    useState<boolean>(false);
  const [displayDetailsModalPassport, setDisplayDetailsModalPassport] =
    useState<boolean>(false);
  const [rates, setRates] = useState<Array<IBankRates>>([]);
  const capacity = useAppSelector(selectStoreCapacity);
  // console.log('capacity', capacity);
  // console.log("dataFetched", dataFetched);
  const dispatch = useAppDispatch();
  const REACT_APP_FRIDAA_API_BASE_URI: string | undefined =
    process.env.REACT_APP_FRIDAA_API_BASE_URI;

  useEffect(() => {
    // console.log('USE EFFECT')
    const fetchData = async () => {   
      try {
        // console.log('DATA', {
        //   monthlyIncome: capacity.monthlyIncomes,
        //   monthlyIncomeCoborrower: capacity.coBorrowerIncomes,
        //   personalMonthlyIncome: capacity.mainBorrowerIncomes,
        //   monthlyCredits: capacity.monthlyCredits,
        //   personalContribution: capacity.personalContribution,
        //   projectType: capacity.estateType,
        //   type: capacity.estateType,
        //   constructPrice: capacity.constructPrice,
        //   landPrice: capacity.landPrice,
        //   price: capacity.price,
        //   worksPrice: capacity.worksPrice,
        //   age: capacity.age,
        //   ageCoBorrower: capacity.ageCoBorrowerValue,
        //   duration: capacity.duration,
        //   source: process.env.REACT_APP_PARTNER_SOURCE,
        //   fundingId: dataFetched.fundingId,
        //   agencyId: localStorage.getItem(`${process.env.REACT_APP_PARTNER_PREFIX}agency-id`)
        // });

        setLoading(true);
        return await apiService
            .post(`${REACT_APP_FRIDAA_API_BASE_URI}/webappfundings/funding`, {
              monthlyIncome: capacity.monthlyIncomes,
              monthlyIncomeCoborrower: capacity.coBorrowerIncomes,
              personalMonthlyIncome: capacity.mainBorrowerIncomes,
              monthlyCredits: capacity.monthlyCredits,
              personalContribution: capacity.personalContribution,
              projectType: capacity.estateType,
              type: capacity.estateType,
              constructPrice: capacity.constructPrice,
              landPrice: capacity.landPrice,
              price: capacity.price,
              worksPrice: capacity.worksPrice,
              age: capacity.age,
              ageCoBorrower: capacity.ageCoBorrowerValue,
              duration: capacity.duration,
              source: process.env.REACT_APP_PARTNER_SOURCE,
              fundingId: dataFetched.fundingId,
              agencyId: localStorage.getItem(`${process.env.REACT_APP_PARTNER_PREFIX}agency-id`)
            })
            .then((res: any) => {
              // console.log('je boucle test')
              setLoading(false);
              if (res.data && res.data.fundingId) {
                setDataFetched(res.data);
              }
            });
      } catch (error) {
        console.log("error while fetching", error);
        setLoading(false);
        return [];
      }
    };

    if (capacity.monthlyIncomes && capacity.monthlyIncomes !== 0 && !loading) {
      fetchData();
    }
  }, [capacity]);

  useEffect(() => {
    dispatch(updateFundingStoreCapacity(capacityService.instantiateCapacity()));
    setIsDesktop(window.innerWidth > 769);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** FETCH RATES */
  useEffect(() => {
    bankRatesService.fetchAll().then((res: Array<IBankRates>) => setRates(res));
  }, []);

  useEffect(() => {
    if (!loading) {
      console.log('res.data', dataFetched.rate)
      dispatch(
        updateFundingStoreCapacity({
          rate: dataFetched.rate,
          result: dataFetched.result,
          resultWithInsurance: dataFetched.resultWithInsurance,
          newGlobalFees: dataFetched.newGlobalFees,
          borrowingCapitalWithInsurance:
            dataFetched.borrowingCapitalWithInsurance,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataFetched.result,
    dataFetched.resultWithInsurance,
    dataFetched.newGlobalFees,
    dataFetched.borrowingCapitalWithInsurance
  ]);

  /** UPDATE STORE monthlyPayment CAPACITY */
  useEffect(() => {
    if (!loading) {
      dispatch(
        updateFundingStoreCapacity({ monthlyPayment: dataFetched.debtCapacity })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetched.debtCapacity]);

  useEffect(() => {
    if (!loading) {
      const sliderMin = 0;
      const sliderMax = Math.max(0, dataFetched.debtCapacity);
      const nextMontlyPayment = Math.max(
        sliderMin,
        Math.min(sliderMax, capacity.monthlyPayment ?? dataFetched.debtCapacity)
      );

      dispatch(
        updateFundingStoreCapacity({ monthlyPayment: nextMontlyPayment })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("Result: ", result);

  useEffect(() => {
    dispatch(
      updateFundingStoreCapacity({
        monthlyIncomes:
          capacity.mainBorrowerIncomes + capacity.coBorrowerIncomes,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacity.mainBorrowerIncomes, capacity.coBorrowerIncomes]);

  const handleInputValueBlockChange = (event: any) => {
    dispatch(
      updateFundingStoreCapacity({
        [event.target.name]: event.target.value,
      })
    );
  };

  const handleDurationChange = (newDuration: number) => {
    dispatch(updateFundingStoreCapacity({ duration: newDuration }));
  };

  const handleEstateTypeChange = (newEstateType: number | null) => {
    switch (newEstateType) {
      case 0:
        dispatch(updateFundingStoreCapacity({ landPrice: 0, constructPrice: 0, worksPrice: 0 }));
        break;
      case 1:
        dispatch(updateFundingStoreCapacity({ landPrice: 0, constructPrice: 0 }));
        break;
      case 2:
        dispatch(updateFundingStoreCapacity({ price: 0, worksPrice: 0 }));
        break;
      case 3:
        dispatch(updateFundingStoreCapacity({ landPrice: 0, constructPrice: 0, worksPrice: 0 }));
        break;
    }
    dispatch(updateFundingStoreCapacity({ estateType: newEstateType }));
  };

  const handleResultClick = () => {
    logEvent(analytics, EFirebaseEvents.CAPACITY_DETAILS);
    setDisplayDetailsModal(!displayDetailsModal);
    setDisplayDetailsModalPassport(!displayDetailsModalPassport);
  };

  const handlePasssportClick = () => {
    setDisplayDetailsModalPassport(!displayDetailsModalPassport);
  };

  const handleSubmit = () => {
    logEvent(analytics, EFirebaseEvents.CAPACITY_SAVE);
    setDisplayDownloadAppModal(true);
  };

  const handleSubmitInputValueBlock = (eventName: string): void => {
    const event: string | undefined = get(
      EFirebaseEvents,
      `CAPACITY_${eventName}_UPDATE`
    );

    if (event !== undefined) {
      logEvent(analytics, event);
    }
  };

  const displayInputValueBlocks = () => {
    return (
      <div className="flex space-between inputs-values-blocks">
        <InputValueBlock
          iconName="picto-revenus"
          name="monthlyIncomes"
          modalNames={["mainBorrowerIncomes", "coBorrowerIncomes"]}
          label="revenus"
          value={capacity.monthlyIncomes}
          modalValues={[
            capacity.mainBorrowerIncomes,
            capacity.coBorrowerIncomes,
          ]}
          CBHandleChange={handleInputValueBlockChange}
          CBHandleSubmit={handleSubmitInputValueBlock}
        />
        <InputValueBlock
          iconName="picto-credit"
          name="monthlyCredits"
          modalNames={["monthlyCredits"]}
          label="crédit"
          value={capacity.monthlyCredits}
          modalValues={[capacity.monthlyCredits]}
          CBHandleChange={handleInputValueBlockChange}
          CBHandleSubmit={handleSubmitInputValueBlock}
        />
        <InputValueBlock
          iconName="picto-apport"
          name="personalContribution"
          modalNames={["personalContribution"]}
          label="apport"
          value={capacity.personalContribution}
          modalValues={[capacity.personalContribution]}
          CBHandleChange={handleInputValueBlockChange}
          CBHandleSubmit={handleSubmitInputValueBlock}
        />
      </div>
    );
  };

  return (
    <div id="capacityFunding">
      <div className="content">
      {dataFetched.status === 0 && (
        <h1>Votre financement<br/>semble difficile</h1>
      )}
      {dataFetched.status === 1 && (
        <h1>Votre passeport<br/>crédit est prêt !</h1>
      )}
        {displayInputValueBlocks()}
        
        <PropertyFundingTypeSelector
          CBHandleChange={handleEstateTypeChange}
          projectPrice={dataFetched.projectPrice}
        />

        <LoanDurationSelector
          actualDuration={capacity.duration}
          CBHandleClick={handleDurationChange}
        />

        
        <ColoredResultCard
          title="Ma capacité"
          subtitle={dataFetched.insurance ? "Avec assurance" : "Sans assurance"}
          value={roundFor(Math.round((dataFetched.resultWithInsurance)))}
          backgroundColor={isDekstop ? "white" : "#e9f2ff"}
          CBHandleClick={handleResultClick}
          status={dataFetched.status}
        />

        {!loading && dataFetched.status === 0 && (
          <h6 className="tips-funding">
            Pour bénéficier d'un meilleur financement nous vous conseillons de
            revoir vos infos financières. (ex : l'apport, la durée souhaitée,
            le montant du bien etc) 🏡
          </h6>
        )}

        {(dataFetched.status === 1)  && (
          <DiscoverFundingPasseportButton
            callback={handlePasssportClick}
            title={"MON PASSEPORT CRÉDIT"}
          />
        )}
        
        {displayDetailsModalPassport && (
          <PassportModal
            CBHandleClose={handlePasssportClick}
            dataFetched={dataFetched}
          />
        )}

        {/* <DiscoverAppButton callback={handleSubmit} /> */}

        <div className="legal-text">
          Il ne s'agit pas d'un accord de crédit, seules les banques sont autorisées à octroyer ou non un crédit.
        </div>

        {displayDetailsModal && (
          <CapacityDetailsModal
            CBHandleClose={handleResultClick}
            dataFetched={dataFetched}
          />
        )}

        {displayDownloadAppModal && (
          <DownloadAppModal
            CBHandleClose={() => setDisplayDownloadAppModal(false)}
            dataFetched={dataFetched}
          />
        )}
      </div>
    </div>
  );
};

export default CapacityFundingScreen;
