import React, { useEffect, useState } from "react";
import "./scss/Capacity.scss";

/** COMPONENTS */
import InputValueBlock from "components/common/InputValueBlock/InputValueBlock.component";
import LoanDurationSelector from "components/common/LoanDurationSelector/LoanDurationSelector.component";
import PropertyTypeSelector from "components/common/PropertyTypeSelector/PropertyTypeSelector.component";
import SliderInput from "components/common/SliderInput/SliderInput.component";
import ColoredResultCard from "components/ColoredResultCard/ColoredResultCard.component";
import CapacityDetailsModal from "components/CapacityDetailsModal/CapacityDetailsModal.component";

/** ENUMS */
import { EFirebaseEvents } from "enums/EFirebaseEvents.enum";

/** FIREBASE */
import { logEvent } from "firebase/analytics";
import analytics from "firebase-config";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** INTERFACES */
import { IBankRates } from "interfaces/IBankRates.interface";

/** LODASH */
import { get } from "lodash";

/** SERVICES */
import capacityService from "services/capacity.service";
import bankRatesService from "services/bankRates.service";

/** STORE */
import {
	selectStoreCapacity,
	updateStoreCapacity,
} from "features/capacity/capacitySlice";

/** UTILS */
import { roundFor } from "utils/roundFor.util";

const CapacityScreen: React.FC = () => {
	const [isDekstop, setIsDesktop] = useState<boolean>(false);
	const [displayDownloadAppModal, setDisplayDownloadAppModal] =
		useState<boolean>(false);
	const [displayDetailsModal, setDisplayDetailsModal] =
		useState<boolean>(false);
	const [rates, setRates] = useState<Array<IBankRates>>([]);
	const capacity = useAppSelector(selectStoreCapacity);
	const dispatch = useAppDispatch();

	const capacityInfos = capacityService.generateCapacityInfos(
		capacity.monthlyIncomes,
		capacity.monthlyCredits,
		capacity.personalContribution,
		capacity.monthlyPayment,
		capacity.duration,
		capacity.type,
		rates
	);
	console.log('capacityInfos', capacityInfos);

	useEffect(() => {
		dispatch(updateStoreCapacity(capacityService.instantiateCapacity()));
		setIsDesktop(window.innerWidth > 769);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/** FETCH RATES */
	useEffect(() => {
		bankRatesService.fetchAll().then((res: Array<IBankRates>) => setRates(res));
	}, []);

	useEffect(() => {
		dispatch(
			updateStoreCapacity({
				result: capacityInfos.result,
				newGlobalFees: capacityInfos.newGlobalFees,
				borrowingCapitalWithInsurance:
					capacityInfos.borrowingCapitalWithInsurance,
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		capacityInfos.result,
		capacityInfos.newGlobalFees,
		capacityInfos.borrowingCapitalWithInsurance,
	]);

	/** UPDATE STORE monthlyPayment CAPACITY */
	useEffect(() => {
		dispatch(
			updateStoreCapacity({ monthlyPayment: capacityInfos.debtCapacity })
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [capacityInfos.debtCapacity]);

	useEffect(() => {
		const sliderMin = 0;
		const sliderMax = Math.max(0, capacityInfos.debtCapacity);
		const nextMontlyPayment = Math.max(
			sliderMin,
			Math.min(sliderMax, capacity.monthlyPayment ?? capacityInfos.debtCapacity)
		);

		dispatch(updateStoreCapacity({ monthlyPayment: nextMontlyPayment }));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// console.log("Result: ", result);

	useEffect(() => {
		dispatch(
			updateStoreCapacity({
				monthlyIncomes:
					capacity.mainBorrowerIncomes + capacity.coBorrowerIncomes,
			})
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [capacity.mainBorrowerIncomes, capacity.coBorrowerIncomes]);

	const handleInputValueBlockChange = (event: any) => {
		dispatch(
			updateStoreCapacity({
				[event.target.name]: event.target.value,
			})
		);
	};

	const handleDurationChange = (newDuration: number) => {
		dispatch(updateStoreCapacity({ duration: newDuration }));
	};

	const handleEstateTypeChange = (newEstateType: number | null) => {
		dispatch(updateStoreCapacity({ type: newEstateType }));
	};

	const handleSliderChange = (newValue: number) => {
		dispatch(updateStoreCapacity({ monthlyPayment: newValue }));
	};

	const handleResultClick = () => {
    logEvent(analytics, EFirebaseEvents.CAPACITY_DETAILS)
		setDisplayDetailsModal(!displayDetailsModal);
	};

	const handleSubmit = () => {
		logEvent(analytics, EFirebaseEvents.CAPACITY_SAVE)
		setDisplayDownloadAppModal(true);
	};

	const handleSubmitInputValueBlock = (eventName: string): void => {
		const event: string | undefined = get(EFirebaseEvents, `CAPACITY_${eventName}_UPDATE`);

		if (event !== undefined) {
			logEvent(analytics, event)
		}
	}

	const displayInputValueBlocks = () => {
		return (
			<div className="flex space-between inputs-values-blocks">
				<InputValueBlock
					iconName="picto-revenus"
					name="monthlyIncomes"
					modalNames={["mainBorrowerIncomes", "coBorrowerIncomes"]}
					label="revenus"
					value={capacity.monthlyIncomes}
					modalValues={[
						capacity.mainBorrowerIncomes,
						capacity.coBorrowerIncomes,
					]}
					CBHandleChange={handleInputValueBlockChange}
					CBHandleSubmit={handleSubmitInputValueBlock}
				/>
				<InputValueBlock
					iconName="picto-credit"
					name="monthlyCredits"
					modalNames={["monthlyCredits"]}
					label="crédit"
					value={capacity.monthlyCredits}
					modalValues={[capacity.monthlyCredits]}
					CBHandleChange={handleInputValueBlockChange}
					CBHandleSubmit={handleSubmitInputValueBlock}
				/>
				<InputValueBlock
					iconName="picto-apport"
					name="personalContribution"
					modalNames={["personalContribution"]}
					label="apport"
					value={capacity.personalContribution}
					modalValues={[capacity.personalContribution]}
					CBHandleChange={handleInputValueBlockChange}
					CBHandleSubmit={handleSubmitInputValueBlock}
				/>
			</div>
		);
	};

	return (
		<div id="capacity">
			<div className="content">
				<h1>Ma capacité d'achat</h1>

				{displayInputValueBlocks()}

				<LoanDurationSelector
					actualDuration={capacity.duration}
					CBHandleClick={handleDurationChange}
				/>

				<PropertyTypeSelector CBHandleChange={handleEstateTypeChange} />

				<SliderInput
					label="Mensualité"
					value={Math.ceil(
						Math.min(
							capacity.monthlyPayment,
							Math.max(0, capacityInfos.debtCapacity)
						)
					)}
					minimum={0}
					maximum={Math.ceil(capacityInfos.debtCapacity)}
					CBOnChange={handleSliderChange}
					unit="€/mois"
				/>

				<ColoredResultCard
					title="Ma capacité d'achat"
					value={roundFor(capacity.result, 1000)}
					backgroundColor={isDekstop ? "white" : "#e9f2ff"}
					CBHandleClick={handleResultClick}
				/>

				{/* <SaveButton callback={handleSubmit} /> */}

				{displayDetailsModal && (
					<CapacityDetailsModal data={capacityInfos} CBHandleClose={handleResultClick} />
				)}

				{/* {displayDownloadAppModal && (
					<DownloadAppModal
						CBHandleClose={() => setDisplayDownloadAppModal(false)}
					/>
				)} */}
			</div>
		</div>
	);
};

export default CapacityScreen;
