import React, { useState } from "react";
import "./scss/OnboardingFundingNextButton.scss";

/** ASSETS */
import { ReactComponent as Arrow } from "assets/svg/white-right-arrow.svg";

/** ENUMS */
import { EFirebaseEvents } from "enums/EFirebaseEvents.enum";

/** FIREBASE */
import analytics from "firebase-config";
import { logEvent } from "@firebase/analytics";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** REACT ROUTER DOM */
import { useHistory } from "react-router-dom";

/** SERVICES */
import onboardingService from "services/onboardingFunding.service";

/** STORE */
import {
  onboardingAddSteps,
  selectStoreOnboarding,
  selectStoreOnboardingStep,
  selectStoreOnboardingType,
  updateStoreOnboarding,
} from "features/onboarding/onboardingFundingSlice";

interface IProps {
  isEmpty?: boolean;
  isEmpty1?: boolean;
  isEmpty2?: boolean;
  isEmpty3?: boolean;
  setIsClicked?: any;
  setIsClicked1?: any;
  setIsClicked2?: any;
  setIsClicked3?: any;
}

const OnboardingNextButton: React.FC<IProps> = ({ isEmpty,isEmpty1,isEmpty2,isEmpty3, setIsClicked, setIsClicked1, setIsClicked2, setIsClicked3 }) => {
  let history = useHistory();
  const onboardingData = useAppSelector(selectStoreOnboarding);
  const actualStep: number = useAppSelector(selectStoreOnboardingStep);
  const dispatch = useAppDispatch();
  const [selectedType, setSelectedType] = useState<number | null>(
    useAppSelector(selectStoreOnboardingType)
  );

  // const isError: boolean = useAppSelector(
  //   selectStoreOnboardingIsError
  // );

  // console.log("onboardingData", onboardingData);

  const handleClick = (): void => {
    // console.log('HANDLE CLICK');
    // console.log('IS EMPTY', isEmpty);
    // console.log('IS EMPTY 1', isEmpty1);
    // console.log('IS EMPTY 2', isEmpty2);
    // console.log('IS EMPTY 3', isEmpty3);

    if (!isEmpty && !isEmpty1 && !isEmpty2 && !isEmpty3) {
      // console.log("isEmpty");
      // setIsClicked(false);

      dispatch(
        updateStoreOnboarding({
          isError: false,
        })
      );

      if (actualStep === 5) {
        onboardingService.saveOnboardingOnLocalStorage(onboardingData);
        history.push("/capacityFunding");
      } else {
        if (actualStep === 0) {
          logEvent(analytics, EFirebaseEvents.ONBOARDING_INCOMES_VALIDATION);
        }
        if (actualStep === 1) {
          logEvent(analytics, EFirebaseEvents.ONBOARDING_CREDITS_VALIDATION);
        }
        if (actualStep === 4) {
          switch (selectedType) {
            case 0:
              dispatch(updateStoreOnboarding({ landPrice: 0, constructPrice: 0, worksPrice: 0 }));
              break;
            case 1:
              dispatch(updateStoreOnboarding({ landPrice: 0, constructPrice: 0 }));
              break;
            case 2:
              dispatch(updateStoreOnboarding({ price: 0, worksPrice: 0 }));
              break;
            case 3:
              dispatch(updateStoreOnboarding({ landPrice: 0, constructPrice: 0, worksPrice: 0 }));
              break;
          }
        }
        dispatch(onboardingAddSteps(1));
      }
    } else if (isEmpty) {
      setIsClicked(true);
      dispatch(
        updateStoreOnboarding({
          isError: true,
        })
      );
    } else if (isEmpty1) {
      setIsClicked1(true);
      dispatch(
        updateStoreOnboarding({
          isError: true,
        })
      );
    } else if (isEmpty2) {
      setIsClicked2(true);
      dispatch(
        updateStoreOnboarding({
          isError: true,
        })
      );
    } else if (isEmpty3) {
      setIsClicked3(true);
      dispatch(
        updateStoreOnboarding({
          isError: true,
        })
      );
    }
  };

  return (
    <div className="flex content-center onboarding-next-button">
      <button
        className="flex content-center items-center"
        onClick={handleClick}
      >
        <span>Continuer</span>

        <Arrow />
      </button>
    </div>
  );
};

export default OnboardingNextButton;
