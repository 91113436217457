import React, { useEffect } from "react";
import "./scss/Homepage.scss";

/** ENUMS */
import { EFirebaseEvents } from "enums/EFirebaseEvents.enum";

/** FIREBASE */
import { logEvent } from "firebase/analytics";
import analytics from "firebase-config";

/** REACT ROUTER */
import { useHistory } from "react-router";
import { useLocation } from 'react-router-dom';

/** SVGS */
// import logo from "assets/png/logo-fridaaXpartner-ok.png";
import arrow from "assets/svg/white-right-arrow.svg";
import { ReactComponent as HouseHomepage } from "assets/svg/house-homepage.svg";

const HomepageScreen: React.FC = () => {
  let history = useHistory();
  
  // handle url query params
	const location = useLocation();
  const paramPrice = new URLSearchParams(location.search).get(`${process.env.REACT_APP_PARTNER_PREFIX}price`) ?? '';
  localStorage.setItem(`${process.env.REACT_APP_PARTNER_PREFIX}price`, paramPrice);
  const paramAgency = new URLSearchParams(location.search).get(`${process.env.REACT_APP_PARTNER_PREFIX}agency-id`) ?? '';
  localStorage.setItem(`${process.env.REACT_APP_PARTNER_PREFIX}agency-id`, paramAgency);
  const paramFirstnameBorrower = new URLSearchParams(location.search).get(`${process.env.REACT_APP_PARTNER_PREFIX}firstname-borrower`) ?? '';
  localStorage.setItem(`${process.env.REACT_APP_PARTNER_PREFIX}firstname-borrower`, paramFirstnameBorrower);
  const paramLastnameBorrower = new URLSearchParams(location.search).get(`${process.env.REACT_APP_PARTNER_PREFIX}lastname-borrower`) ?? '';
  localStorage.setItem(`${process.env.REACT_APP_PARTNER_PREFIX}lastname-borrower`, paramLastnameBorrower);
  const paramFirstnameCoborrower = new URLSearchParams(location.search).get(`${process.env.REACT_APP_PARTNER_PREFIX}firstname-coborrower`) ?? '';
  localStorage.setItem(`${process.env.REACT_APP_PARTNER_PREFIX}firstname-coborrower`, paramFirstnameCoborrower);
  const paramLastnameCoborrower = new URLSearchParams(location.search).get(`${process.env.REACT_APP_PARTNER_PREFIX}lastname-coborrower`) ?? '';
  localStorage.setItem(`${process.env.REACT_APP_PARTNER_PREFIX}lastname-coborrower`, paramLastnameCoborrower);
  
  useEffect(() => {
    if (window.innerWidth > 769) {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    }
  }, []);

  const handleClickCapacity = (): void => {
    logEvent(analytics, EFirebaseEvents.HOMEPAGE_TEST_BUTTON)
    history.push("onboarding");
  };

  const handleClickFunding = (): void => {
    logEvent(analytics, EFirebaseEvents.HOMEPAGE_TEST_BUTTON)
    history.push("onboardingFunding");
  };

  return (
    <div id="homepage" className="column">
      <div className="column content">
        <div className="column">
          <h1>Bienvenue chez</h1>
          <img src={process.env.REACT_APP_FRIDAA_PARTNER_LOGO_URI} className="logo" alt="fridaa-homepage-logo" />
        </div>

        <div className="intro">
          <p>
            Découvrez <span>en quelques clics votre capacité d'achat ou la faisabilité de votre financement</span> et obtenez votre passeport crédit !
          </p>
        </div>

        <div className="start-buttons">
          <div className="start-buttons-wrapper">
            <button onClick={handleClickCapacity}>
              <span>Ma capacité d'achat</span>
              <div className="column content-center">
                <img src={arrow} alt="white-arrow" />
              </div>
            </button>
            <button onClick={handleClickFunding}>
              <span>Mon financement</span>
              <div className="column content-center">
                <img src={arrow} alt="white-arrow" />
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="ellipse"></div>
      <div className="illu">
        <HouseHomepage />
      </div>
    </div>
  );
};

export default HomepageScreen;
