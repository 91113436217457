import React, { useState } from "react";
import "../scss/SideBarCapacity.scss";

/** COMPONENTS */
import FinancialInfos from "components/common/FinancialFundingInfos/FinancialFundingInfos.component";

/** ENUMS */
import { EFirebaseEvents } from "enums/EFirebaseEvents.enum";

/** FIREBASE */
import { logEvent } from "firebase/analytics";
import analytics from "firebase-config";

/** LODASH */
import { get } from "lodash";

/** SVGS */
// import { ReactComponent as FridaaLogo } from "assets/svg/fridaa-logo-name.svg";
import { ReactComponent as FridaaLogo } from "assets/svg/logo-fridaaXapimo.svg";

/** STORE */
import { useAppDispatch, useAppSelector } from "hooks";
import { selectStoreCapacity, updateFundingStoreCapacity } from "features/capacity/capacityFundingSlice";
// import logo from "assets/png/logo-fridaaXpartner-ok.png";

const SideBarCapacity: React.FC = () => {
	const dispatch = useAppDispatch();
	const capacity: any = useAppSelector(selectStoreCapacity);
	console.log('CAPACITY', capacity);
	const [values, setValues] = useState({
		monthlyIncomes: capacity.monthlyIncomes?.valueOf(),
		mainBorrowerIncomes: capacity.mainBorrowerIncomes?.valueOf(),
		coBorrowerIncomes: capacity.coBorrowerIncomes?.valueOf(),
		monthlyCredits: capacity.monthlyCredits?.valueOf(),
		personalContribution: capacity.personalContribution?.valueOf(),
	});
	console.log('VALUES', values);
	
	const CBHandleChange = (event: any) => {
		console.log("CBHandleChange", event.target.name);
		setValues({
			...values,
			[event.target.name]: event.target.value
		})
	};

	const handleSubmit = (eventName: string): void => {
		dispatch(
			updateFundingStoreCapacity({
				...values,
				monthlyIncomes: values.mainBorrowerIncomes + values.coBorrowerIncomes
			})
		);

		const event: string | undefined = get(EFirebaseEvents, `CAPACITY_${eventName}_UPDATE`);

		if (event !== undefined) {
			logEvent(analytics, event)
		}
	}

	return (
		<div className="column space-between sidebar-capacity">
			<div className="logo">
				<a href="/">
          			<img src={process.env.REACT_APP_FRIDAA_PARTNER_LOGO_URI} className="logo" alt="fridaa-homepage-logo" />
				</a>
			</div>

			<FinancialInfos
				monthlyIncomes={capacity.mainBorrowerIncomes + capacity.coBorrowerIncomes}
				monthlyIncomesCapacity={capacity.monthlyIncomes}
				mainBorrowerIncomes={values.mainBorrowerIncomes}
				coBorrowerIncomes={values.coBorrowerIncomes}
				monthlyCredits={values.monthlyCredits}
				monthlyCreditsCapacity={capacity.monthlyCredits}
				personalContribution={values.personalContribution}
				personalContributionCapacity={capacity.personalContribution}
				CBHandleChange={CBHandleChange}
				CBHandleSubmit={handleSubmit}
			/>
		</div>
	);
};

export default SideBarCapacity;
