import React from "react";
import "./scss/OnboardingFundingScreen.scss";

/** COMPONENTS */
import OnboardingFundingNavigator from "components/OnboardingNavigator/OnboardingFundingNavigator.component";

const OnboardingFundingScreen: React.FC = () => {

	return (
		<div id="onboarding" className="flex">
			<OnboardingFundingNavigator />
		</div>
	);
};

export default OnboardingFundingScreen;
