import React, { useState } from "react";
import "./scss/CapacityFundingDetailsModal.scss";

/** ASSETS */
import { ReactComponent as CreditIcon } from "assets/svg/credit-picto-white.svg";
import { ReactComponent as ChevronIcon } from "assets/svg/blue-chevron-left.svg";
import { ReactComponent as OvalValid } from "assets/svg/oval-valid.svg";
import { ReactComponent as OvalInvalid } from "assets/svg/oval-invalid.svg";

/** COMPONENTS */
import DetailsTab from "components/common/DetailsFundingTab/DetailsFundingTab.component";
import DetailsItem from "components/common/DetailsFundingItem/DetailsFundingItem.component";

/** ENUMS */
import { EFirebaseEvents } from "enums/EFirebaseEvents.enum";

/** FIREBASE */
import { logEvent } from "firebase/analytics";
import analytics from "firebase-config";

/** MATERIAL UI */
import { Dialog, DialogContent } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

/** STORE */
import { useAppSelector } from "hooks";
import { selectStoreCapacity } from "features/capacity/capacityFundingSlice";
import DownloadAppModal from "components/common/DownloadAppModal/DownloadAppModal.component";
import { roundFor } from "utils/roundFor.util";

interface IProps {
  CBHandleClose: () => void;
  dataFetched?: any;
}

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props: any, ref: any) {
    const isDesktop: boolean = window.innerWidth > 769;

    return <Slide direction={isDesktop ? "left" : "up"} ref={ref} {...props} />;
  }
);

const CapacityDetailsModal: React.FC<IProps> = ({
  CBHandleClose,
  dataFetched,
}) => {
  const capacity: any = useAppSelector(selectStoreCapacity);
  console.log('CAPACITY', capacity.rate, typeof capacity.rate);
  const [displayDownloadModal, setDisplayDownloadModal] =
    useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);

  const handleClick = (name: string) => {
    if (name === "Ma capacité") {
      logEvent(analytics, EFirebaseEvents.CAPACITY_DETAILS_RATE_PRESSED);
      setTabValue(0);
    }
    if (name === "status") {
      setTabValue(1);
    }
  };

  return (
    <>
      <Dialog
        className="capacity-details-modal-funding"
        open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={CBHandleClose}
      >
        <DialogContent>
          <div className="column modal-dialog-content">
            <button className="close" onClick={CBHandleClose}>
              <ChevronIcon />
            </button>

            <div className="header">
              <h2>Détails</h2>
            </div>

            <div className="container">
              <DetailsTab
                items={["Ma capacité", "status"]}
                CBHandleClick={handleClick}
              />
              {tabValue === 0 && (
                <div>
                  <DetailsItem
                    title="Ma capacité"
                    content="Montant que vous pourriez rembourser chaque mois."
                    value={roundFor(Math.round(dataFetched.resultWithInsurance))}
                    subValue={`sur ${dataFetched.duration} ans`}
                    suffix={" €"}
                    noBackground={true}
                  />

                  <DetailsItem
                    icon={<CreditIcon />}
                    title={"Emprunt"}
                    content={
                      "Il s'agit du montant que vous pourriez emprunter à la banque."
                    }
                    value={Math.round(
                      dataFetched.amountBorrowed
                    )}
                    suffix={" €"}
                  />

                  <DetailsItem
                    icon={<CreditIcon />}
                    title={"Taux"}
                    content={"Taux d'emprunt estimé pour ce projet"}
                    // EDIT HERE
                    value={capacity.rate ? Math.round(capacity.rate * 10000) / 100.0 : 0}
                    suffix={" %"}
                  />
                  <DetailsItem
                    icon={<CreditIcon />}
                    title={"Intérêts"}
                    content={
                      "Ce moment représente le coût estimés pour ce projet."
                    }
                    // EDIT HERE
                    value={Math.round(dataFetched.creditCost)}
                    suffix={" €"}
                  />

                  <DetailsItem
                    icon={<CreditIcon />}
                    title={"Frais"}
                    content={
                      "Ces frais correspondent aux frais de garantie et aux frais de notaire estimés."
                    }
                    value={Math.round(dataFetched.fees ?? 0)}
                    suffix={" €"}
                  />
                </div>
              )}
              {tabValue === 1 && (
                <div>
                  <DetailsItem
                    title="Statut"
                    value={
                      dataFetched.status === 0
                        ? "Financement difficile"
                        : "Financement facile"
                    }
                    noBackground={true}
                  />

                  <DetailsItem
                    icon={
											(dataFetched.debtCapacityStatus === 0 ? (
												<OvalInvalid />
											) : (
												<OvalValid />
											)) as any
										}
										title={"Endettement"}
										content={
											dataFetched.debtCapacityStatus === 0
												? "Le taux d'endettement simulé dépasse les 35%."
												: "Le taux d'endettement simulé ne dépasse pas les 35%."
										}
										value={Math.round(dataFetched.debtCapacity * 100)}
										suffix={" %"}
										iconStatus={true}
                  />

                  <DetailsItem
                    icon={
											(dataFetched.personalContributionStatus === 0 ? (
												<OvalInvalid />
											) : (
												<OvalValid />
											)) as any
										}
										title={"Apport"}
										content={
											dataFetched.personalContributionStatus === 0
												? "Un apport personnel ne couvrant pas les frais complexifie votre financement."
												: "Un apport personnel couvrant les frais facilite votre financement"
										}
										value={dataFetched.personalContribution}
										suffix={" €"}
										iconStatus={true}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="legal-text">
            Il ne s'agit pas d'un accord de crédit, seules les banques sont autorisées à octroyer ou non un crédit.
          </div>
        </DialogContent>
      </Dialog>

      {displayDownloadModal && (
        <DownloadAppModal
          CBHandleClose={() => setDisplayDownloadModal(false)}
        />
      )}
    </>
  );
};

export default CapacityDetailsModal;
