import React, { useState } from "react";
import "../scss/OnboardingProperty.scss";

/** COMPONENTS */
import OnboardingNextButton from "components/common/OnboardingFundingNextButton/OnboardingFundingNextButton.component";
import OnboardingPropertyTypeSelector from "components/common/OnboardingPropertyTypeSelector/OnboardingPropertyTypeSelector.component";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** STORE */
import { updateStoreOnboarding } from "features/onboarding/onboardingFundingSlice";

const OnboardingHousing: React.FC = () => {
	const dispatch = useAppDispatch();
	const [displayModal, setDisplayModal] = useState<boolean>(false);

	const handleEstateTypeChange = (newEstateType: number | null) => {
		dispatch(updateStoreOnboarding({ estateType: newEstateType }));
	};

	return (
		<div className="column onboarding-incomes">
			<h1>Quel type de bien voulez-vous acheter ?</h1>

			<div className="flex input-cards">
				<OnboardingPropertyTypeSelector
					CBHandleChange={handleEstateTypeChange}
					CBHandleClose={() => setDisplayModal(false)}
					open={true}
				/>
			</div>

			<OnboardingNextButton />
		</div>
	);
};

export default OnboardingHousing;
