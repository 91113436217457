import React from "react";
import "../scss/SideBarOnboarding.scss";

/** SVGS */
// import logo from "assets/svg/fridaa-homepage-logo.svg";
// import logo from "assets/png/logo-fridaaXpartner-ok.png";

const SideBarOnboarding: React.FC = () => {
  return (
    <div className="sidebar-onboarding">
      <div className="column content-center content">
        <div className="content-header">
          <h2>Bienvenue chez</h2>
          <img src={process.env.REACT_APP_FRIDAA_PARTNER_LOGO_URI} className="logo" alt="fridaa-homepage-logo" />
        </div>

        <div className="intro">
          <p>
            Découvrez en quelques clics{" "}
            <span>la faisabilité de votre financement</span> et obtenez votre{" "}
            <span>passeport crédit.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SideBarOnboarding;
