import React from "react";
import "./scss/CapacityFundingTypeInputCard.scss";

interface IProps {
  label: string;
  inputName: string;
  value: any ;
  CBHandleChange: (event: any) => void;
  units: Array<string>;
}

const CapacityFundingTypeInputCard: React.FC<IProps> = ({
  label,
  inputName,
  value,
  CBHandleChange,
  units,
}) => {
  return (
    <div className=" onboarding-funding-passport-input-card">
      <div className="headers-label">
        <div className="label">
          <span>{label}</span>
        </div>
      </div>

      <div className={"flex items-center content-center value"}>
        <input
          name={inputName}
          value={value}
          onChange={CBHandleChange}
          required
          // style={{width: (value.length + 1) * 13}}
        />
        {units.length > 0 && (
          <div className="units">
            <span>{units[0]}</span>

            {/* {units.length === 2 && <span>{units[1]}</span>} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default CapacityFundingTypeInputCard;
