import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

/** REACT GTM MODULE */
import TagManager from "react-gtm-module";

/** REACT ROUTER */
import { Provider } from "react-redux";

/** STORE */
import { store } from "./store";

const tagManagerArgs = {
	gtmId: process.env.REACT_APP_GOOGLE_ANALYTICS_TAG ?? "",
};

if (process.env.REACT_APP_GOOGLE_ANALYTICS_TAG != null) {
	TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
